const en = {
  BACK: "Back",
  BALANCE: "Balance",
  GO_BACK: "Go back",
  CLOSE: "Close",
  COPY: "Copy",
  COPIED: "Copied!",
  OTHERS: "Others",
  ALL: "All",
  TOTAL: "Total",
  CONFIRM: "Confirm",
  CONFIRMED: "confirmed",
  PURCHASE: "Purchase",
  SALE: "Sale",
  TRADE: "Trade",
  WARNING: "Warning",
  SEARCH: "Search",
  NO_DATA: "No data",
  PAGE: "Page",
  OF: "of",
  TO: "to",
  SHOW_ALL: "Show All",
  PREVIOUS: "Previous",
  NEXT: "Next",
  FROM: "from",

  BUY_CRYPTO: "Buy crypto",
  SELL_CRYPTO: "Sell crypto",
  BUY_SELL_CRYPTO: "Buy/Sell crypto",
  PROFILE_MENU: "Profile-menu",
  METAMASK_MENU: "MetaMask-menu",

  PROFILE_SETTINGS: "Profile settings",
  VERIFICATION: "Verification",
  ACTIVITY_LOG: "Activity log",
  REFERRALS: "Referrals",
  LOG_OUT: "Log out",
  DISCONNECT_METAMASK: "Disconnect MetaMask",
  ADD_ANOTHER_WALLET: "Add another wallet",

  TRADE_REVIEW: "Trade review",
  RATE: "Rate",
  YOU_SPEND: "You Spend",
  YOU_RECEIVE: "You Receive",
  FEES: "Fees",
  DONT_SHOW_AGAIN: "Don't show this confirmation again",
  WITHOUT_CONFIMATION: "Proceed without confirmation step",
  CLOSE_MODAL: "close modal",
  CLOSE_SELECT: "close select",
  TO_SPEND: "To spend",
  TO_RECEIVE: "To receive",
  ERROR_NO_AMOUNT: "The amount exceeds your balance",
  ERROR_NO_AMOUNT_STAKE: "The amount exceeds your stake",
  SELECT_ASSET: "Select asset",

  DASHBOARD: "Dashboard",
  STAKING: "Staking",
  WALLET: "Wallet",
  HISTORY: "History",
  FEATURES: "Features",
  FUTURES: "Futures",
  PROFILE: "Profile",

  ASSETS: "Assets",
  AMOUNT: "Amount",
  PRICE: "Price",
  VALUE: "Value",
  D7_GRAPH: "7d Graph",
  H24: "24h",

  ID: "ID",
  DATE: "Date",
  TYPE: "Type",
  CURRENCY: "Currency",
  FEE: "Fee",

  ACCOUNT_NAME: "User",
  COIN: "Coin",

  ACTIVITY: "Activity",
  DEVICE: "Device",
  BROWSER: "Browser",
  PLACE: "Place",

  TOTAL_BALANCE: "Total balance",
  FEE_SCHEDULE: "Fee schedule",
  TRADING_VOLUME_30: "30-day trading volume",
  TRADE_BALANCES: "Trade balances",
  POSITION_VALUATION: "Position valuation",

  CURRENT_FEE: "Current fee",
  NEXT_FEE: "Next fee",
  MARGIN_TRADE_BALANCE: "Margin trade balance",
  MARGIN_EQUITY: "Margin equity",
  USED_MARGIN: "Used margin",
  FREE_MARGIN: "Free margin",
  MARGIN_LEVEL: "Margin level",
  OPENING_COST: "Opening cost",
  CURRENT_VALUATION: "Current valuation",
  PROFIT_LOSS: "Profit / Loss",

  LADGER: "Ladger",
  ALL_CURRENCIES: "All Currencies",
  ALL_TRANSACTIONS: "All Transactions",
  START_DATE_TIME: "Start date/time",
  END_DATE_TIME: "End date/time",
  FILTER: "Filter",
  TRANSFER: "Transfer",
  WITHDRAWAL: "Withdrawal",
  DEPOSIT: "Deposit",

  LOGIN: "Login",
  NEW_IP_REGISTERED: "New IP registered",
  SIGN_UP: "Sign up",

  MARKET: "Market",
  LIMIT: "Limit",
  DONE: "Done",

  ACCOUNT_VERIFICATION: "Account verification",
  SCAN_QR_PHONE: "Scan the QR code using your phone's camera",
  ALTERNATIVELY: "Alternatively",
  CLICK_TO_CONTINUE: "Click here to continue using current device",
  REFERRAL_LIST: "Invited users",
  YOUR_REFERRAL: "Your referral link",
  COPY_LINK: "Copy link",

  EMAIL_ADDRESS: "Email address",
  CONFIRMED_UP: "Confirmed",
  VERIFICATION_STATUS: "Verification status",
  UNVERIFIED: "Unverified",
  PROVIDE_INFO:
    "Before you can deposit and withdraw funds, please provide  the following information and the supporting documents to verify your account.",
  VERIFY: "Verify",
  DEFAULT_CURRENCY: "Default currency",
  SECURITY_SETTINGS: "Security settings",
  PASSWORD: "Password",
  CHANGE: "Change",
  TWO_FACT_AUTH: "2 Factor Authentication",
  DISABLED: "Disabled",
  ENABLED: "Enabled",
  TWO_FACT_RECOMMEND:
    "We strongly recommend securing your account with two-factor authentication (2FA).",
  ACTIVITY_TYPE: "Activity Type",
  VERIFY_IDENTITY:
    "Verify your account to access more features and higher limits",
  IN_PROGRESS: "In progress",
  FINISHED: "Finished",
  PROVIDE_PERSONAL_INFO: "Provide personal info",
  MINUTES: "minutes",
  REQUIRES: "Requires",
  PROVIDE: "Provide",
  PROVIDE_FINANCIAL_INFO: "Provide financial info",
  A_FEW_DAYS: "A few days",

  REQUIREMENTS: "Requirements",
  COLLAPSE_REQUIREMENTS: "Collapse all requirements",
  EXPAND_REQUIREMENTS: "Expand all requirements",

  CRYPTO_DEPOSIT: "Cryptocurrency deposits",
  CRYPTO_OPERATIONS: "Buy / sell / convert cryptocurrency",
  CRYPTO_WITHDRAWALS: "Cryptocurrency withdrawals",
  BANK_TRANSFERS: "Bank transfers",
  TRADE_WITH_MARGIN: "Trade with margin",
  STACKING_REWARDS: "Earn rewards with staking",
  CRYPTO_TRADE: "Trade crypto futures",
  UNLIMITED: "Unlimited",
  DAY_K: "$5K/day",
  DAY_HIGH_K: "$1K/day",
  DAY_MONTH_K: "$100K/day, $500K/month",

  STAKE: "Stake",
  UNSTAKE: "Unstake",
  STAKED: "Staked",
  AMOUNT_TO_STAKE: "Amount to stake",
  AMOUNT_TO_UNSTAKE: "Amount to unstake",
  YEARLY_REWARD: "Yearly reward",
  APPROXIMATELY: "Approximately",
  REWARDS: "Rewards",
  WEEKLY: "Weekly",
  UNSTACKING_FEE: "Unstaking fee",
  UNSTAKE_CONFIRMATION: "Unstake confirmation",
  UNSTAKE_CONFIRM: "Are you sure you want to unstake your",
  UNSTAKE_INITIATED: "Unstaking initiated",

  CHOOSE_METHOD_ASSET: "Choose asset or payment method",
  CHECK_DEPOSIT_ADDRESS_1: "Please double-check the",
  CHECK_DEPOSIT_ADDRESS_2: "deposit address!",
  SENDING_FUNDS_INFO:
    "Sending funds to an incorrect address may result in a permanent loss of your cryptocurrency.",
  DEPOSIT_ADDRESS: "Deposit address",
  CONFIRM_PROCEED_INFO:
    "I understand that It may take up to 48 hours to proceed.",
  COPY_DEPOSIT_ADDRESS: "Copy deposit address",
  SENDING_FUNDS_CASH_INFO: "Please double-check the EUR deposit address!",
  SENDING_CASH_FUNDS_INFO:
    "Sending funds to an incorrect address may result in a permanent loss of your money.",

  WITHDRAW: "Withdraw",
  CHOOSE_METHOD: "Choose payment method",
  WITHDRAW_WARN_1: "Only withdraw",
  WITHDRAW_WARN_2: "addresses.",
  WITHDRAW_WARN_3:
    "Withdrawals to addresses generated for other networks could result in loss of funds.",
  WITHDRAW_REVIEW: "Review withdraw",

  TOTAL_REWARDS: "Total rewards",
  STAKING_BALANCE: "Staking balance",
  STAKING_ASSETS: "Staking assets",
  STAKING_OPTIONS: "Staking options",
  REWARD_PERCENT: "Reward %",
  TRANSACTIONS: "Transactions",
  ASSET: "Asset",
  COST: "Cost",
  STATUS: "Status",
  REWARD: "Reward",

  IN_ORDERS: "In orders",
  FREE: "Free",
  MARGIN_PORTFOLIO: "Margin Portfolio Value (USD)",
  USED_FREE_MARGIN: "Used/Free margin",
  UNREALIZED_P_L: "Unrealized P/L",
  FUNDING_LIMITS: "Funding limits",
  WITHDRAWAL_LIMITS: "Withdrawal limits",
  CASH: "Cash",
  CRYPTO: "Crypto",
  DAILY_DEPOSITS: "Daily deposits",
  DAILY_WITHDRAWALS: "Daily withdrawals",
  MONTHLY_DEPOSITS: "Monthly deposits",
  MONTHLY_WITHDRAWALS: "Monthly withdrawals",
  YEARLY_DEPOSITS: "Yearly deposits",
  YEARLY_WITHDRAWALS: "Yearly withdrawals",
  DAILY: "Daily",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  RECENT_TRANSACTIONS: "Recent transactions",
  CRYPTO_BALANCES: "Crypto balances",
  CASH_BALANCES: "Cash balances",

  NEW_ORDER: "New order",
  ORDERS: "Orders",
  POSITIONS: "Positions",
  TRADES: "Trades",
  PAIR: "Pair",
  EXECUTED: "Executed",
  VOLUME: "Volume",
  ORDER_TYPE: "Order type",
  CURRENCY_PAIR: "Currency pair",
  LEVERAGE: "Leverage",
  SCHEDULED_START: "Scheduled start",
  SCHEDULED_EXPIRE: "Scheduled expire",
  REASON: "Reason",
  VOLUME_EXECUTED: "Volume executed",
  AVERAGE_PRICE: "Average price",
  OPENED: "Opened",
  CLOSED: "Closed",
  MAKER_FEE: "Maker fee",
  TAKER_FEE: "Taker fee",
  TOTAL_FEE: "Total fee",
  OPEN: "Open",
  TERMS: "Terms",
  ORDER: "Order",
  TO_ORDER: "Order",
  SELL: "Sell",
  FOR: "for",
  REQUESTED_DOCUMENTS: "Requested documents",
  DOCUMENTS: "Documents",
  FILL_FORM: "Fill form",

  ACCOUNT_LOGIN: "Account Login",
  EMAIL: "Email",
  ENTER_EMAIL: "Enter your email",
  ENTER_PASSWORD: "Enter your password",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  ENTER_CURRENT_PASSWORD: "Enter current password",
  RESET_PASSWORD: "Reset Password",
  FORGOT_PASSWORD: "Forgot password",
  REFERRAL_CODE: "Referral Code",
  OR: "Or",
  CONTINUE_WITH: "Continue with",
  NO_ACCOUNT: "Don’t have an account?",
  HAVE_ACCOUNT: "Already have an account?",
  CREATE_ACCOUNT: "Create Account",
  LOG_IN: "Log in",
  AGREE_WITH: "By clicking on 'Sign up', you agree to the",
  TERMS_AND_CONDITIONS: "Terms and conditions",
  CONTINUE: "Continue",
  PASSWORD_RECOVERY: "Password recovery",
  LEARN_MORE: "Learn more",
  EMAIL_NOT_RECEIVE: "Didn’t receive an email?",
  RESEND_IN: "Resend in",
  RESEND_EMAIL: "Resend email",
  CREATE_NEW_PASSWORD: "Create new password",
  ENTER_NEW_PASSWORD: "Enter new password",
  CONFIRM_PASSWORD: "Confirm password",
  CREATE: "Create",
  PASSWORD_SUCCESS_CHANGE: "Password was successfully changed",
  SECURITY_CHANGE_WARNING:
    "In order to protect your account, withdrawals will be disabled for 24 hours after you make this change.",
  RESET_LINK_SENT: "Email with password recovery link has been sent to",
  CLICK_TO_RESEND: "Click button below to resend.",
  EMAIL_CONFIRMED: "Email confirmed – Welcome to Global Unit Pay!",
  EMAIL_CONFIRMED_TEXT:
    "Great news! Your email has been confirmed, and your Global Unit Pay account is now active. Get ready to dive into our community.",
  DEAR: "Dear",
  EXPLORE_FNT: "Explore Global Unit Pay",
  SEND_VERIFY_TO_EMAIL: "Send verify to email",
  NO_WALLETS_YET: "There are no wallets yet",
  ADD_WALLET: "Add wallet",
  SELECT_NETWORK: "Select Network",
  VERIFY_EMAIL: "Please verify your email",
  EMAIL_SENT: "You're almost there! We sent an email to",
  EMAIL_SENT_INFO:
    "Just click on the link in that email to complete your signup. If you don't see it, you may need to check your spam folder.",
  CANT_FIND_EMAIL: "Still can't find the email?",
  NEED_HELP: "Need help?",
  CONTACT_US: "Contact us",
  SOMETHING_GOES_WRONG: "Something goes wrong...",
  EMAIL_NOT_CONFIRMED_TEXT:
    "Please try later or contact support in case if email is still not verified",
  PROCEED_INFO: "The procedure may take up to 48 hours to proceed.",
  LIMIT_ERROR: "Limit price can't be more or less than 10% of market price.",
  SEND: "Send",
  PERSONAL_DATA: "Personal data",
  FIRST_NAME: "First name",
  ENTER_FIRST_NAME: "Enter first name",
  LAST_NAME: "Last name",
  ENTER_LAST_NAME: "Enter last name",
  COUNTRY: "Country",
  CHOOSE_COUNTRY: "Choose country",
  CITY: "City",
  ENTER_CITY: "Enter city",
  STREET: "Street",
  ENTER_STREET: "Enter street",
  POST_CODE: "Post code",
  ENTER_POST_CODE: "Enter post code",
  RESIDENCE: "Residence",
  CHOOSE_RESIDENCE: "Choose residence",
  PHONE_NUMBER: "Phone number",
  ENTER_PHONE_NUMBER: "Enter phone number",
  DATE_OF_BIRTH: "Date of birth",
  ENTER_DATE_OF_BIRTH: "Enter Date of birth",
  ERROR_MIN_AMOUNT: "Amount is lower than minimum",
  ERROR_MAX_AMOUNT: "Amount is higher than maximum",
  AVAILABLE: "Available",
  NOT_AVAILABLE: "Not Available",
  LIMIT_PRICE: "Limit price",
  EXCHANGE: "Exchange",
  BUY_PRODUCT: "Buy product",
  COMMISSION: "Fee",
  SPEND: "Spend",
  LIMIT_ORDER_PRICE: "Limit order price",
  OPEN_ORDERS: "Open orders",
  ORDERS_HISTORY: "Orders history",
  CANCELED: "Canceled",
  EXPIRED: "Expired",
  PROGRESS: "Progress",
  ACTIVE: "Active",
  CANCELLED: "Cancelled",
  NO_ACTIVE_STAKES: "Active stakes not found",
  MARKET_PURCHASE_CONFIRMED: "Purchase at market price confirmed",
  MARKET_SALE_CONFIRMED: "Sale at market price confirmed",
  LIMIT_ORDER_CONFIRMED: "Limit order placed",
  ACCURAL_PERIOD: "Accrual period",
  GAMES: "Games",
  CHOOSE_GAMES: "Choose games",
  CARDS: "Cards",
  FIXED_FEE: "Fixed fee",
  ORDER_ID: "Order ID",
  CARD_NAME: "Card name",
  CARD_NOM: "Card nom.",
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed",
  GIFT_CARDS: "Gift cards",
  MY_GIFT_CARDS: "My gift cards",
  MY_ARCHIVED_CARDS: "My archived cards",
  CARD_PRICE: "Card price",
  ENABLE: "Enable",
  DISABLE: "Disable",
  BALANCE_BEFORE_TRANSACTION: "Balance before transaction",
  PAY_WITH: "Pay with",
  BUY: "Buy",
  BLOCKCHAIN_TO_USE: "Blockchain to use",
  CURRENTLY_UNAVAILABLE_PRODUCT: "Sorry, but product currently unavailable",
  HIDE_EMPTY_WALLETS: "Hide empty wallets",
  CRYPTO_CARDS: "Crypto cards",
  RESUBMIT: "Resubmit",
  NO_WALLETS_FOUND: "No wallets found",
  POOL: "Pool",
  STAKE_CONFIRM: "Are you sure you want to stake your",
  CURRENTLY_UNAVAILABLE_PAIR: "Sorry, but currently you cant purchase card by",
  EARNING: "Earning",

  EMAIL_INVALID: "Email is invalid",
  EMAIL_REQUIRED: "Email is required",
  PASSWORD_INVALID: "Password is invalid",
  PASSWORD_REQUIRED: "Password is required",
  PASSWORD_DIFFERENT: "Passwords are different",
  ACCEPT_TERMS_REQUIRED: "accept terms and conditions is required",
  FIAT: "Fiat",
  CONTACT_SUPPORT: "Contact support",
  MERCHANT: "Merchant",
  CARD_AMOUNT: "Card amount",
  TRANSACTION_AMOUNT: "Transaction amount",
  CURRENTLY_UNAVAILABLE_CURRENCY:
    "Sorry, but this currency currently unavailable for transaction",
  BALANCES: "Balances",
  WITHDRAW_CARD: "Withdraw card",
  TOP_UP_CARD: "Top Up card",
  LAST_EXCHANGE_TRANSACTIONS: "Last exchange transactions",
  MINIMUM_DEPOSIT: "Min deposit",
  MAXIMUM_DEPOSIT: "Max deposit",
  BANK_TRANSFER_REFUND: "Refund",
  BANK_TRANSFER_DEPOSIT: "Bank Transfer Deposit",
  BANK_TRANSFER_WITHDRAW: "Bank Transfer Withdraw",
  EXCHANGE_PROVIDER_COMMISSION: "Exchange Provider Commission",
  OVERPAYMENT: "Overpayment",
  BET: "Bet",
  TOURNAMENT_WINNING: "Tournament winning",
  DETAILS: "Details",
  ACCEPT: "Accept",
  COOKIES_DESC:
    "This website uses cookies to ensure you get the best experience on our website.",
  DELIVERY_ADDRESS: "Delivery address",
  ADD_DELIVERY_ADDRESS: "Add delivery address",
  ADD_NEW_DELIVERY_ADDRESS: "Add new delivery address",
  DELIVERY_METHOD: "Delivery method",
  STANDARD_MAIL: "Standard mail",
  COURIER: "Courier",
  ADDRESS: "Address",
  ENTER_ADDRESS: "Enter address",
  CARD: "Card",
  MANAGE_CARD: "Manage card",
  MY_CRYPTO_CARDS: "My crypto cards",
  BUY_NEW_CARD: "Buy new card",
  LAST_TRANSACTIONS: "Last transactions",
  ORDER_CARD: "Order card",
  COMING_SOON: "Coming soon",
  TOTAL_PRICE: "Total price",
  DELIVERY_RESTRICTIONS:
    "Currently delivery is available only to countries of EEA, UK and Switzerland (Your addresses from other countries will not be displayed)",
  OVERVIEW: "Overview",
  CARD_COST: "Card cost",
  PLASTIC_COST: "Plastic cost",
  DELIVERY_COST: "Delivery cost",
  ORDER_PLASTIC_CARD: "Order plastic card",
  TWO_FA_FOR_DETAILS: "You need to enable 2fa to see Card Details",
  TWO_FA_FOR_PIN: "You need to enable 2fa to see Pin Code",
  CARD_DETAILS: "Card details",
  VIEW_DETAILS: "View details",
  PURCHASE_PLASTIC: "Purchase plastic",
  VIEW_PIN: "View PIN",
  CARD_TOP_UP_LIMITS: "Card Top Up limits",
  CARD_TOP_UP_LIMITS_UPGRADE:
    "This is the card limits that you can increase by upgrading your verification level.",
  DAILY_TOP_UPS: "Daily top ups",
  MONTHLY_TOP_UPS: "Monthly top ups",
  YEARLY_TOP_UPS: "Yearly top ups",
  INCREASE_LIMITS: "Increase limits",
  TOTAL_TO_SPEND: "Total to spend",
  TOTAL_TO_RECEIVE: "Total to receive",
  AMOUNT_TO_RECEIVE: "Amount to receive",
  AMOUNT_TO_RECEIVE_BEFORE_FEE: "Amount to receive before fee",
  AMOUNT_TO_SPEND: "Amount to spend",
  AMOUNT_TO_SPEND_BEFORE_FEE: "Amount to spend before fee",
  TOP_UP: "Top Up",
  ENTER_AMOUNT: "Enter amount",
  SELECT_AMOUNT: "Select amount",
  HOW_TO_USE: "How to use",
  CRYPTO_CARD_ACTIVATE:
    "The card will be activated upon completion of the purchase process.",
  LIMITS: "Limits",
  KYC_VERIFICATION_INFO_REQUIERED:
    "Account Verification and Additional Personal Information Required",
  KYC_WARNING_COUNTRY:
    "Warning! Crypto cards are available only for EEA, UK and Switzerland residence.",
  KYC_VERIFICATION_REQUIRED:
    "- Buying crypto cards requires account verification at the Middle level or higher, and don't worry, it only takes about 4-5 minutes.",
  KYC_VERIFICATION_DOCS_REQUIERED:
    "- After account verification at the Middle level or higher it also requires additional user Personal Information and Proof of Address. (Information approving may last a day or more)",
  KYC_VERIFICATION_APPROVE_IN_PROCESS:
    "Currently your documents are submitted and are on review... It may take some time",
  KYC_VERIFICATION_ACCESS_REQUEST:
    "- As final step you will need to request access. (Access approving may last a week or more)",
  KYC_VERIFICATION_DOCS_APPROVED:
    "Your additional user Personal Information and Proof of Address are approved! Now you can Request Access to use this service!",
  KYC_VERIFICATION_ERROR:
    "Your Access request was rejected. Please contact our",
  KYC_REQUEST_PENDING:
    "Access approving is pending. You need to wait. (It may last a week or more)",
  FILL_FORMS: "Fill Forms",
  VERIFY_ACCOUNT: "Verify account",
  REQUEST_ACCESS: "Request Access",
  KYC_VERIFICATION_SUPPORT: "support.",
  FORM_VERIFICATION_ERROR: "Your documents were rejected. Please contact our",
  FORM_VERIFICATION_SUPPORT: "support",
  FORM_VERIFICATION_ERROR_END: " for details",
  UPLOAD: "Upload",

  VIRTUAL: "Virtual",
  PHYSICAL: "Physical",
  CASHBACK: "Cashback",
  CARD_TRANSFER_CASHBACK: "Cashback",
  REJECT_REASON: "Reject reason:",
  PREVIOUS_REJECT_REASON: "Previous reject reason:",
  PAY_METHOD: "Payment method",
  DIVIDEND: "Dividend",
  INCLUDING_FEES: "Including fees",
  NETWORK_FEE: "Network fee",
  SERVICE_FEE: "Service fee",
  MINIMUM_AMOUNT_IS: "Minimum amount is",
  AMOUNT_MUST_BE_LESS_THAN: "Amount must be less than",
  ERROR_CREATING_ADDRESS: "Error while creating new wallet address",
  ERROR_CREATING_ADDRESS_CRYPTO: "(it is needed for buying crypto)",
  TRY_AGAIN: "Try again",
  ACTIVATE: "Activate",
  CARD_ACTIVATION: "Card activation",
  ACTIVATE_PLASTIC: "Activate plastic",
  ENTER_CODE_TO_ACTIVATE_CARD: "Enter code to activate card",
  ENTER_CODE: "Enter code",
  SUCCESS_CARD_ACTIVE: "Card activation is successful",
  UP_TO: "Up to",
  YOU_SPENT: "You spent",
  LOGGED_FROM_ADMIN_PANEL: "You are logged on user account from admin panel",
  NEW_WALLET_ADDRESS_CREATING: "New wallet address is creating. Please wait...",
  TOP_UP_FEES: "Top up fees",
  WITHDRAW_FEES: "Withdraw fees",
  TRANSACTION_FEES: "Transaction fees",
  UNKNOWN_PAYBIS_ERROR:
    "Unknown error has occurred while loading Paybis widget. Most commonly it is unavailable for legal reasons",
  CARD_LIMITS: "Card limits",
  MONTHLY_TRANSACTIONS: "Monthly transactions",
  CODE: "Code",
  FACE_VALUE: "Face Value",
  CARD_INFO: "Card Info",
  DRAG_AND_DROP_INFO: "Drag and drop file here or click to select file",
  ASSET_OR_PAYMENT_METHOD: "Asset or payment method",
  CRYPTO_CARD_REVIEW: "Crypto card review",
  GIFT_CARD_REVIEW: "Gift card review",
  CHANGE_PASSWORD: "Change password",
  PASSWORD_CHANGED_SUCCESSFULLY: "Password changed successfully",
  TRANSACTION_REVIEW: "Transaction review",
  WITHDRAW_WAS_SUCCESSFUL: "Withdraw was successful",
  TOP_UP_WAS_SUCCESSFUL: "Top up was successful",
  NOW_YOUR_BALANCE: "Now your card balance is",
  YOU_RECEIVED: "You Received",
  DEPOSIT_VERIFY_REQUIRED:
    "deposit requires account verification at the SENIOR level.",
  BANK: "Bank",
  BANK_ADDRESS: "Bank Address",
  DEPOSIT_WARNING:
    "WARNING! It is REQUIRED to fill this 'code' in details of transaction! Otherwise transaction won't come through!",
  PURCHASE_PLASTIC_CARD: "Purchase plastic card",
  PLASTIC_CARD_SUCCESS_PURCHASE: "Plastic card successfully ordered",
  START_EARN_AGAIN_1: "You can start earning rewards on your",
  START_EARN_AGAIN_2: "again anytime.",
  WITHDRAW_VERIFY_REQUIRED:
    "Withdraw requires account verification at the MIDDLE level or higher",
  WITHDRAW_FIAT_VERIFY_REQUIRED:
    "withdraw requires account verification at the SENIOR level.",
  ENTER_NAME: "Enter name",
  ENTER: "Enter",
  ENTER_DETAILS: "Enter details",
  PROCEDURE_WARN:
    "I understand that the procedure may take up to 48 hours to proceed.",
  TWO_FA_FOR_WITHDRAW: "You need to enable 2fa to use Withdraw",
  CARD_BALANCE: "Card balance",
  TWO_FA_FOR_CARD_DETAILS: "Enter 2FA Code to see Card Details",
  TWO_FA_FOR_PIN_CODE: "Enter 2FA Code to see Pin Code",
  CARD_NUMBER: "CARD NUMBER",
  EXPIRY_DATE: "EXPIRY DATE",
  SECURITY_CODE: "SECURITY CODE",
  CARD_PIN_CODE: "Card PIN code",
  KEEP_PIN_CODE_SAFE:
    "Keep your PIN code confidential, never share it with anyone.",
  TRANSACTION_EXCEEDS_LIMITS_DAILY: "Transaction exceeds your daily limits",
  TRANSACTION_EXCEEDS_LIMITS_MONTHLY: "Transaction exceeds your monthly limits",
  TRANSACTION_EXCEEDS_LIMITS_YEARLY: "Transaction exceeds your yearly limits",
  OUT_OF_LIMITS: "Out of limits",
  INCREASE_LIMITS_TEXT:
    "To increese limits you can complete verification on higher level",
  SENIOR_VERIFICATION: "Senior Verification",
  SENIOR_VERIFICATION_TEXT_1:
    "To qualify for Senior verification and access to fiat currency deposits and withdrawals, please use the link below to schedule a video call with one of our specialists.",
  SENIOR_VERIFICATION_TEXT_2:
    "Please ensure your passport or another valid document is ready to read the MRZ code and facial recognition. If you reside in a country different from your citizenship, please also provide a residence permit for that country during the video call.",
  SENIOR_VERIFICATION_TEXT_3:
    "Providing false information beforehand will be reported to relevant authorities, banks, and KYC/AML providers, resulting in special markings on your profile.",
  SENIOR_VERIFICATION_SCHEDULE:
    "To schedule a video call at your convenience, please use the following link:",
  SENIOR_VERIFICATION_LINK_1:
    "Once you have booked a time slot, please use the link",
  SENIOR_VERIFICATION_LINK_2: "to access the video call.",
  SENIOR_VERIFICATION_ADVISE:
    "We advise preparing in advance for the video call and ensuring optimal video quality for the verification process.",
  SENIOR_VERIFICATION_QUESTIONS_1:
    "If you have any questions or encounter any issues, please don't hesitate to contact our",
  SENIOR_VERIFICATION_QUESTIONS_2: "support team.",
  SCAN_QR_BY_AUTH_APP: "Scan QR code by Authentication App",
  MANUALY_ENTER_TO_AUTH_APP:
    "Or manually enter this secret to Authentication App",
  ENTER_CODE_FROM_AUTH_APP: "Enter code from Authentication App below",
  ACCOUNT_VERIFICATION_REQUIRED: "Account Verification Required",
  NO_CARDS_FOUND: "No cards found",
  CHOOSE_CARDS_DESIGN: "Choose card design",
  NO_CARDS_ACTIVE_YET: "You don't have any active crypto cards yet",
  NO_GIFT_CARDS_ACTIVE_YET: "You don't have any active gift cards yet",
  SUBMIT: "Submit",
  ERROR_LOADING_GAME: "Error loading game",
  REFRESH: "Refresh",
  PURCHASE_CARD_RESTRICTION:
    "Purchasing card requires account verification at the Junior level or higher, and don't worry, it only takes about 2 minutes.",
  BUY_CARD: "Buy card",
  BUY_SAME_CARD: "Buy same card",
  CARD_TITLE: "Card title",
  SAVE: "Save",
  EDIT: "Edit",
  CARD_NOTES: "Card notes",
  NO_PENDING_DOCS: "There is no pending documents' requests",
  NOTE: "Note",
  SELL_CRYPTO_NOTE:
    "The sale will not be automatic, you will need to make a transfer to the wallet provided by the provider at the end",
  COMPANY: "Company",
  VERIFY_TO_USE_SITE:
    "Sorry, but you can't fully use website until your verification level is 'Senior'.",
  REQUIRED_FIELD: "Required field",
  INVALID_PHONE_NUMBER: "Invalid phone number",
};

export default en;
